import { ref } from "vue";
import axios from "axios";
import authHeader from "@/Api/AuthHeader";

// const URL_API = "https://terrenos.co.mz/Api/api/" ;
// const URL_API = "http://localhost:8000/api/" ;
const URL_API = process.env.VUE_APP_URL_API;




export default{
    namespaced:true,
    actions:{
        async addProporsal(data){
           return await axios.post(URL_API+"proporsal", data, {headers:authHeader()});
        },

        async getProporsal(queryParams){
            return await axios.get(URL_API+`proporsal?page=${queryParams.page}&search=${queryParams.search}`, {headers:authHeader()})
        },
        async getProporsalBy(id){
            return await axios.get(URL_API+`proporsal/${id}`, {headers:authHeader()})

        },
        async getProduct_ProporsalBy(queryParams){
            return await axios.get(URL_API+`getProduct_ProporsalBy?page=${queryParams.page}&search=${queryParams.search}&pid=${queryParams.pid}`, {headers:authHeader()})

        },
        async update(data, id){
            return await axios.patch(URL_API+`proporsal/${id}`, data, {headers:authHeader()});

        },

        async delete(id){
            return await axios.delete(URL_API+`proporsal/${id}`, {headers:authHeader()});

        }
    }
}