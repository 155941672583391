<template>
  <div class="container mb-30 bg-white " >
    <div class="row navMenu">  
        <div class="col-lg-2  d-lg-block d-none">
        <div class="row">
        <div class="py-2 px-3 align-items-center" style="height:10px"> 
            <a href="/" class="h1 text-uppercase text-primary px-2 btn   align-items-center  w-100"
             style="height: 65px; padding: 0 10px"
            >
            
                <img src="/TerrenosLogo.png" class="img-fluid" style="width:35px; height:35px" alt="logo">
                   
                <!-- <img src="http://localhost:8080/assets/img/logo/Terrenos - Trespasse de Espaço (Blue).png" class="img-fluid" style="width:50px; height:50px" alt=""> -->
               <!-- <i class="	fa fa-language"></i>
                   <span  @click="changeLanguage('EN')">EN</span> / <span  @click="changeLanguage('pt_pt')">PT</span> 
                    -->
            </a> 
        </div>
        <div class="py-4 px-3 align-items-center">
           <a style="font-size:12px" @click="changeLanguage('EN')"> <i class="	fa fa-language"></i> EN</a>/<a style="font-size:12px"  @click="changeLanguage('pt_pt')">PT</a> 
        </div>

            
        </div>
               <!-- <a
                   href="javascript:void(0)" 
                  class="d-flex"
                   > 
                     <i class="	fa fa-language"></i>
                   <span  @click="changeLanguage('EN')">EN</span> / <span  @click="changeLanguage('pt_pt')">PT</span> 
                   
                   </a> -->
 
                   
        </div>
         
      <div class="col-lg-3 d-none d-lg-block ">
          <a 
          v-if="props.role == 'Agent'"
          class="btn d-flex align-items-center justify-content-between bg-white w-100"
          data-toggle="collapse"
          href="#navbar-vertical"
          style="height: 65px; padding: 0 10px"
        >
          <h6 class="text-dark m-0" v-if="path == '/'" style="visibility: hidden;">
             {{t('BarMenu.Categories')}}
          </h6>
          <h6 class="text-dark m-0" v-else-if="path == '/login'" style="visibility: hidden;">
             {{t('BarMenu.Categories')}}
          </h6>
          <h6 class="text-dark m-0" v-else-if="path == '/inscription'" style="visibility: hidden;">
             {{t('BarMenu.Categories')}}
          </h6>
          <h6 class="text-dark m-0" v-else>
             Menu
          </h6>
          <i class="fa fa-angle-down text-dark"></i>
        </a>
 
        <nav
          v-if="props.role == 'Agent'"
          class="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light "
          id="navbar-vertical"
          style="width: calc(100% - 30px); z-index: 999"
        >
          <div class="navbar-nav w-100">
            <div class="nav-item dropdown dropright">
              <a
                href="#"
                class="nav-link dropdown-toggle"
                data-toggle="dropdown"
                >{{t('BarMenu.Product')}} <i class="fa fa-angle-right float-right mt-1"></i
              ></a>
              <div
                class="dropdown-menu position-absolute rounded-0 border-0 m-0"
              >
                <router-link to="/add-product" class="dropdown-item"
                  >{{t('BarMenu.Add')}} </router-link
                >
                <router-link to="/listMyproducts" class="dropdown-item"
                  >{{t('BarMenu.List')}} </router-link
                >
              </div>
            </div>
          </div>
        </nav>
      </div>

      <div class="col-lg-7  mx-0 ">
        <nav class="navbar navbar-expand-lg bg-white navbar-dark py-3 py-lg-0">
    
          <button
            type="button"
            class="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
             <span class="fa fa-bars text-primary"></span>
          </button>
          <div
            class="collapse navbar-collapse justify-content-between"
            id="navbarCollapse"
          >
            <div class="navbar-nav ml-auto py-0">
              <a href="/" class="nav-item nav-link active">{{t('BarMenu.Home')}}</a>
               <router-link to="/agents" class="nav-item nav-link"
                >
                <!-- <i class="	fa fa-cart-plus"></i> -->
                {{t('BarMenu.Sell')}}</router-link
              >
               <router-link to="/" class="nav-item nav-link">
               <!-- <i class="	fa fa-shopping-basket"></i> -->
               {{t('BarMenu.Buy')}}</router-link>

               <!-- Agence -->
              <div class="nav-item dropdown"> 
               
                <a
                  href="javascript:void(0)"
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  >
                <!-- <i class="fa fa-briefcase"></i> -->
                {{ t('BarMenu.agence') }} <i class="fa fa-angle-down mt-1"></i
                >
                </a>
                
                <div class="dropdown-menu bg-white rounded-0 border-0 m-0" style="z-index:99999">
                   <router-link
                      class="dropdown-item"
        
                      to="/agente"
                      type="button"
                      >{{t('BarMenu.BecomeAgent')}}</router-link
                    >
                 
                    <router-link
                      class="dropdown-item"
        
                      to="/franquia"
                      type="button"
                      >{{t('BarMenu.franchise')}}</router-link
                    >

                 
                    <a
                      v-if="userAuthenticated == true"
                      @click="logout"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px; padding: 0 10px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-sign-out mr-2 text-danger"></i>
                        <span style="font-size: 12px"> {{t('BarMenu.Logout')}}</span>
                      </h6>
                      <i class="fa fa-logout text-dark"></i>
                    </a>
                </div>



              </div>
              <!-- End Agences -->


              
               <div class="nav-item dropdown">

               
               
                <a
                  href="javascript:void(0)"
                  class="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                  >
                <i class="fa fa-user-circle-o"></i>
                {{ t('BarMenu.Account') }} <i class="fa fa-angle-down mt-1"></i
                >
                </a>
                
                <div class="dropdown-menu bg-white rounded-0 border-0 m-0" style="z-index:99999">
                <a
                      v-if="userAuthenticated == true && props.role == 'Agent'"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-cart-plus mr-2 text-success"></i>
                        <router-link
                          class="text-primary"
                          to="/profile"
                          style="font-size: 12px; text-decoration: none"
                        >
                          {{t('BarMenu.MyProduct')}}</router-link
                        >
                      </h6>
                     </a>

                     <a
                      v-if="userAuthenticated == true && props.role == 'Agent'"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-user mr-2 text-primary"></i>
                        <router-link
                          class="text-primary"
                          to="/myData"
                          style="font-size: 12px; text-decoration: none"
                        >
                          {{t('BarMenu.Profile')}}</router-link
                        >
                      </h6>
                     </a>

                  
                    <a
                      v-if="userAuthenticated == true"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-key mr-2 text-primary"></i>
                        <router-link
                          class="text-primary"
                          to="/update_password"
                          style="font-size: 12px; text-decoration: none"
                        >
                          {{t('BarMenu.Credentials')}}</router-link
                        >
                      </h6>
                      <i class="fa fa-logout text-dark"></i>
                    </a>
                    <router-link
                      class="dropdown-item"
                      v-if="!userAuthenticated"
                      to="/login"
                      type="button"
                      >{{t('BarMenu.Login')}}</router-link
                    >
                 
                    <a
                      v-if="userAuthenticated == true"
                      @click="logout"
                      class="btn d-flex align-items-center justify-content-between bg-white w-100"
                      style="height: 45px; padding: 0 10px"
                    >
                      <h6 class="text-dark m-0">
                        <i class="fa fa-sign-out mr-2 text-danger"></i>
                        <span style="font-size: 12px"> {{t('BarMenu.Logout')}}</span>
                      </h6>
                      <i class="fa fa-logout text-dark"></i>
                    </a>
                </div>



              </div>
              <!-- <a
                   href="javascript:void(0)" 
                  class="nav-link"
                   > 
                     <i class="	fa fa-language"></i>
                   <span  @click="changeLanguage('EN')">EN</span> / <span  @click="changeLanguage('pt_pt')">PT</span> </a>
                    -->
                   <div class="nav-item">
                

              </div>
              

       
            </div>

 
          </div>
        </nav>
      </div>
    </div>
  </div>
  <!-- Navbar End -->
</template>

<script setup>
import store from "@/store";
import { computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

const props = defineProps(["fullName", "login", "role", "userLogged", "lang"]);
const route = useRoute();
const path = route.fullPath;
 // const role = ref("");
  const storeData = useStore()

const userAuthenticated = computed(()=>store.state.auth.authenticated)

// onMounted(()=>{
//     // role.value = store.state.auth.user.data.role[0];
//   console.log( store.state.auth.authenticated);
// })

 
 const { t, locale} = useI18n();
const langStore = useStore();
 
function updateLocalData(){
     
    locale.value = langStore.state.auth.lang; // Altera o idioma
}

watch(()=>langStore.state.auth.lang, (newValue, oldValue)=>{
     updateLocalData();
})

function changeLanguage(localLang){
    langStore.dispatch("auth/localLang", localLang);

}

  async function logout() {
       
      localStorage.removeItem("user");
      storeData.dispatch("auth/logout") 
      window.location.href = "/"
    }


 </script>

<style scoped>
  .navMenu {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0, 0.2);
  }
</style>