import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import { MainLayout } from "@/layouts"; ;
import MyLogin from "@/pages/login/MyLogin.vue";   
import Home from "@/pages/Home/Home.vue"
import home_main from "@/components/home_main.vue"

 
const routes = [
  
  {
    path: "/login",
    name: "login",
    component: MyLogin,
    meta: {
      middleware: "guest",
      title: `Login`,
    },
  },

  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ()=>import("@/pages/recover_password/Recover_password.vue"),
    meta: {
      middleware: "guest",
     },
  },

  {
    path:'/agente',
    name:"Ser agente",
    component:()=>import("@/pages/Agents/beAgent.vue"),
    meta:{
      title:'Ser um Agente'
    }
   },
   {
    path:'/franquia',
    name:"franquia",
    component:()=>import("@/pages/Agents/franchise.vue"),
    meta:{
      title:'Franquia'
    }
   },

  {
    path: "/reset_password/:token",
    name: "recover-password",
    component: ()=>import("@/pages/inscriptions/NewPassword.vue"),
    meta: {
      middleware: "guest",
     },
  },

  {
    path: "/inscription",
    name: "inscription",
    component: ()=>import("@/pages/inscriptions/MyInscriptions.vue"),
    meta: {
      middleware: "guest",
      title: `Nova Conta`,
    },
  },


  {
    path: "/dashboard",
    component:home_main,
    meta: {
      middleware: "auth",
      requiresAuth:true,
     },
    children: [
      {
        path:"/dashboard",
        component:()=>import("@/components/MyHome.vue"),
        meta:{
          title:"Dashboard"
        },

        children:[
          {
            path: '/dashboard',
            component: ()=>import("@/pages/Statistic/Statistic.vue"),
            name: "Statistic",
            meta: {
              title: "Statistic"
            }
          },

          {
            path:'/adminProfile',
            component:()=>import("@/pages/profile/MyProfile.vue"),
            meta:{
              title:'Profile'
            }
          },
          {
            path:'/update_credetials',
            component:()=>import("@/pages/recover_password/update_password.vue")
          },

          {
            path:'/editAdminProfile',
            component:()=>import("@/pages/profile/EditProfile.vue"),
            meta:{
              title:'Edit Prodile'
            }
          },
          {
            path:'/add-products',
            component:()=>import("@/pages/Products/addProducts.vue"),
            meta:{
              title:"Products"
            }
          },
          {
            path:'/list-products',
            component:()=>import("@/pages/Products/ListProducts.vue"),
            meta:{
              title:'List-products'
            }
          },
          {
            path:"/list-available-products",
            component:()=>import("@/pages/Products/ListProductsAvailable.vue"),
            meta:{
              title:'List-products'
            }
          },
          {
            path:'/editPorduct/:query',
            name:'editPorduct',
            component:()=>import("@/pages/Products/EditProduct.vue"),
            meta:{
              title:'Edit Product'
            }
          },
          {
            path:'/add-agents',
            name:'Addagents',
            component:()=>import("@/pages/Agents/Agents.vue"),
            meta:{
              title:'Agents'
            }

          },
          {
            path:'/list-agents',
            name:'listagents',
            component:()=>import("@/pages/Agents/ListAgents.vue"),
            meta:{
              title:'Agents'
            }
          },
          {
            path:'/edit-Agent/:id',
            name:'editAgent',
            component:()=>import("@/pages/Agents/EditAgent.vue" ),
            meta:{
              title:"Agent"
            }
          },

          {
            path:'/view-Agent/:id',
            name:'ViewAgent',
            component:()=>import("@/pages/Agents/ViewAgent.vue"),
            meta:{
              title:"Agent"
            }
          },

          {
            path:'/add-proporsal',
            name:"addProporsal",
            component:()=>import("@/pages/Proporsal/Proporsal.vue"),
            meta:{
              title:"Proporsal"
            }
          },
          {
            path:'/list-proporsal',
            name:"ListProporsal",
            component:()=>import("@/pages/Proporsal/ListProporsal.vue"),
            meta:{
              title:"Proporsal"
            }
          },
          {
            path:'/update-proporsal/:id',
            name:"EditProporsal",
            component:()=>import("@/pages/Proporsal/EditProporsal.vue"),
            meta:{
              title:"Actualizar Proposta"
            }
            
          },
          {
            path:'/view-proporsal/:id',
            name:"ViewProporsal",
            component:()=>import("@/pages/Proporsal/ViewProporsal.vue"),
            meta:{
              title:"Actualizar Proposta"
            }
            
          },
          {
            path:'/show-product_proporsal/:id',
            name:"Showproduct_proporsal",
            component:()=>import("@/pages/Proporsal/product_proporsal.vue"),
            meta:{
              title:"Propostas do Producto"
            }
            
          } ,

          {
            path:'/list-beAgent',
            name:"listbeagent",
            component:()=>import("@/pages/Agents/ListBeAgent.vue"),
            meta:{
              title:"Quero ser agente"
            }
            
          } ,

          {
            path:'/list-candidate',
            name:"ListCandidate",
            component:()=>import("@/pages/Agents/ListFranchise.vue"),
            meta:{
              title:"Listar Franquias"
            }
            
          } 
        

        ]

      },
      
    ]
  },


  {
    path: "/",
    name:"Home",
    component: MainLayout,
    meta: {
       title:"Home"
    },
    children: [
       {
        path: "/",
        name: "dashboard",
        component: Home
       },
       {
        path:'/findProductsByDistrict/:id/:selected',
        name:'findByDistrict',
        component:()=>import("@/pages/Home/ProductsByDistrict.vue"),
        meta:{
          title:'Products'
        }
       },
       {
        path:'/agents',
        name:'agents',
        component:()=>import("@/pages/Home/Seller.vue")
       },
       {
        path:'/agentProducts/:id',
        name:'agentProducts',
        component:()=>import("@/pages/Home/AgentProducts.vue"),
        meta:{
          title:'Agent Products'
        }
       },

       {
        path:'/detalhes/:id',
        name:'Home_details',
        component:Home,
        meta:{
          title:'Detalhes Producto'
        }

       },
     
    
     
    ],
  },

  {
    path:'/details/:id',
    name:'Details',
    component:()=>import("@/pages/Home/Details.vue"),
    meta:{
      title:"Details"
    }
  },

  {
    path:'/profile',
    name:'Profile',
    component:()=>import("@/components/mainProfile.vue"),
    requiresAuth:true,
    meta:{
      title:"Profile"
    },

    children:[
      {
        path:'/profile',
        component:()=>import("@/pages/profile/ListProducts.vue"),
        meta:{
          title:'Products'
        }
      },
      {
        path:'/add-product',
        component:()=>import("@/pages/profile/addProducts.vue"),
        mata:{
          title:'Products'
        }
      },
      {
        path:'/listMyproducts',
        component:()=>import("@/pages/profile/ListProducts.vue"),
        mata:{
          title:'Products'
        }
      },
      {
        path:'/update-product/:id',
        component:()=>import("@/pages/profile/EditProduct.vue"),
        name:'UpdateMyProduct',
        meta:{
          title:'Update Product'

        }
      },
      {
        path:"/myData",
        component:()=>import("@/pages/profile/MyProfile.vue"),
        meta:{
          title:'Profile'
        }
      },
      {
        path:'/edit-profile',
        name:'editProfile',
        component:()=>import("@/pages/profile/EditProfile.vue"),
        meta:{
          title:'Profile'
        }
      },
      {
        path:'/update_password',
        component:()=>import("@/pages/recover_password/update_password.vue")
      },
    ]
  },

  {
    path:'/auth/google/call-back/:state?',
    component:()=>import("@/pages/login/AuthLoading.vue"),
    meta:{
      title:"Hello google"
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});


router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
    if (to.meta.requiresAuth && !isAuthenticated()) {
      // redirect user to the login page
       next('/login');
    }else {
       //allow user to access user his role
          next();
    }
 

  // if (to.meta.middleware == "guest") {
  //   if (store.state.auth.authenticated ) {
  //     next("dashboard");
  //   }
  //   next();
  // } else {
  //   if (store.state.auth.authenticated) {
  //     next();
  //   } else {
  //     next({ name: "login" });
  //   }
  // }
});

function isAuthenticated() {
  if (store.state.auth.authenticated) {
       return true;
    }else{
      return false;
    }
  // return true;
}

function hasUserRole(role){
  if (isAuthenticated()) {
    if (store.state.auth.user.data.role == role ) {
       return true;
   }else{
     return false;
   }
    
  }
    // console.log(store.state.auth.user.data.role[0]);
    // return true;
}
export default router;
