<template>
  <div class="container">
    <h4 class="mt-5">{{ $t("HomePage.FindYourProduct") }}</h4>
    
    <ul
      class="nav nav-tabs col-lg-12" 
      role="tablist"
      style="border: none; border-top-right-radius: 10px; width:100%"
    >
      <li class="nav-item" style="border-top-right-radius: 5px">
        <a
          :style="{
            backgroundColor: Imoveis== 1 ? '#FFF' : '#eceff2', 
            borderTopLeftRadius: '5px',color:'#444', paddingLeft:'26px', paddingRight:'26px', fontWeight:'400'}
          "
          class="nav-link active"
          id="custom-content-above-home-tab"
          @click="actualllySelected('Imoveis', 1)"
          data-toggle="pill"
          href="#custom-content-above-home"
          role="tab"
          aria-controls="custom-content-above-home"
          aria-selected="true"
          >{{ $t("HomePage.RealState") }}</a
        >
      </li>
      <li class="nav-item">
        <a
          :style="{
            backgroundColor: Imoveis == 0 ? '#FFF' : '#eceff2', 
            borderTopRightRadius: '5px', color:'#444', paddingLeft:'26px', paddingRight:'26px'}
          "
          class="nav-link"
          id="custom-content-above-profile-tab"
          @click="actualllySelected('Terreno', 0)"
          data-toggle="pill"
          href="#custom-content-above-profile"
          role="tab"
          aria-controls="custom-content-above-profile"
          aria-selected="false"
          >{{ $t("HomePage.Land") }}</a
        >
      </li>
      <li class="nav-item">
         <a class="nav-link"
          :style="{
            backgroundColor: Imoveis == 2 ? '#FFF' : '#eceff2', 
            borderTopRightRadius: '5px', color:'#444', paddingLeft:'26px', paddingRight:'26px'}
          "
          id="custom-content-above-profile-tab" @click="actualllySelected('filters', 2)" data-toggle="pill" href="#custom-content-above-profile" role="tab" aria-controls="custom-content-above-profile" aria-selected="false">Filtros</a>
      </li>
    </ul>

    <div class="tab-content" id="custom-content-above-tabContent">
      <div
        class="tab-pane fade show active"
        id="custom-content-above-home"
        role="tabpanel"
        aria-labelledby="custom-content-above-home-tab"
      >
        <form action="">
          <div class="input-group">
            <input
              :value="search"
              ref="input"
              @input="onInput($event.target.value)"
              type="text"
               class="form-control py-4 form-control-navbar search-text-input"
              :placeholder="$t('HomePage.SearchProductInput')"
            />
            <div class="input-group-append">
              <span class="input-group-text bg-white text-warning">
                <i class="fa fa-search " style="color:#444"></i>
              </span>
            </div>
          </div>
          <div>
            <ul v-show="mutableOptions.length" class="autocomplete-results">
              <li
                class="autocomplete-result"
                v-for="(opt, i) in mutableOptions"
                :key="opt[valueKey]"
                :ref="`option_${i}`"
                tabindex="0"
                @click="setResult(opt.name)"
                @mouseover="setArrowCounter(index)"
              >
                <span class="map"> <i class="fa fa-map-marker"></i> </span>
                {{ opt.name }} 
              </li>
            </ul>
          </div>
        </form>
      </div>
      <div
        class="tab-pane fade"
        id="custom-content-above-profile"
        role="tabpanel"
        aria-labelledby="custom-content-above-profile-tab"
      >
        <form action="">
          <div class="input-group">
            <input
              type="text"
              :value="search"
              ref="input"
 
              @input="onInput($event.target.value)"
              class="form-control py-4 form-control-navbar search-text-input"
              :placeholder="$t('HomePage.SearchProductInput')"
            />
            <div class="input-group-append">
              <span class="input-group-text bg-white text-warning">
                <i class="fa fa-search "  style="color:#444"></i>
              </span>
            </div>
          </div>
          <ul v-show="mutableOptions.length" class="autocomplete-results">
            <li
              class="autocomplete-result"
              @click="setResult(opt.name)"
              v-for="(opt, i) in mutableOptions"
              :key="i"
              :ref="`option_${index}`"
            >
              <span @click="findProducts(opt.name)" class="map">
                <i class="fa fa-map-marker"></i>
              </span>
              {{ opt.name }}
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    isAsync: {
      type: Boolean,
      required: false,
      default: false,
    },

    value: {
      type: String,
      default: "",
    },

    labelKey: {
      type: String,
      default: "label",
    },

    valueKey: {
      type: String,
      default: "id",
    },

    searchMinLength: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      isLoading: false,
      isOpen: false,
      search: "",
      results: [],
      originalOptions: [],
      mutableOptions: [],
      arrowCounter: 0,
      optionSelected: "Imoveis",
      arrowCounter: 0,
      Imoveis:1,
    };
  },


  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  },

  // watch:{
  //     items:function(value, oldValue){
  //         if(value.length !== oldValue.length){
  //             this.results = value;
  //              this.isLoading = false;
  //         }
  //     }
  // },

  methods: {
    changeBackgroundTab(flag){
       
    },
    onInput(evt) {
      this.search = evt;
      this.emitInput();

      if (evt.length >= this.searchMinLength) {
        if (!this.originalOptions.length) {
          this.$emit("shouldSearch", evt);
        } else {
          // this.searchInternally();
        }
      } else {
        this.resetOptions();
      }
      // if (this.isAsync) {
      //     this.isLoading = true;

      // }else{
      //     this.filterResults();
      //     this.isOpen = true;
      // }
    },

    setResult(result) {
      this.search = result;
      this.isOpen = false;
      this.$emit("itemSearchProducts", result);
      this.$emit("routeActuallySelected", result);
      this.emitInput();
      this.resetOptions();
      this.resetArrowCounter();
    },

    filterResults() {
      this.results = this.items.filter(
        (item) => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      );
    },

    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false;
      }
    },

    cloneOptions() {
      this.originalOptions = this.items;
      this.mutableOptions = this.items;
      // this.searchInternally();
    },

    searchInternally() {
      const keyword = this.search;

      this.mutableOptions = this.originalOptions.filter(
        (o) => o[this.labelKey].toLowerCase().search(keyword.toLowerCase()) >= 0
      );
      this.highlightOptions();
    },

    highlightOptions() {
      const keyword = this.search;
      const query = new RegExp(keyword, "i");

      this.mutableOptions.forEach((o) => {
        this.$set(
          o,
          `${this.labelKey}_highlighted`,
          o[this.labelKey].replace(query, '<span class="font-bold">$&</span>')
        );
      });
    },

    resetOptions() {
      this.originalOptions = [];
      this.mutableOptions = [];
    },

    emitInput() {
      this.$emit("input", this.keyword);
    },

    resetKeyword() {
      this.keyword = "";
      this.emitInput();
    },

    onKeydown(evt) {
      if (!this.mutableOptions.length) {
        return;
      }

      switch (evt.code) {
        case "ArrowDown":
          evt.preventDefault();
          this.onArrowDown();
          break;
        case "ArrowUp":
          evt.preventDefault();
          this.onArrowUp();
          break;
        case "Enter":
          this.onSelect();
          break;
        case "Escape":
          this.onEsc();
          break;
      }
    },

    onEsc() {
      this.$refs.input.blur();
      this.resetArrowCounter();
      this.resetOptions();
    },

    onArrowDown() {
      if (this.arrowCounter < this.mutableOptions.length - 1) {
        this.arrowCounter += 1;
      }

      this.fixScrolling();
    },

    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
      }

      this.fixScrolling();
    },

    onBlur(evt) {
      const tgt = evt.relatedTarget;
      if (tgt && tgt.classList.contains("autocomplete-item")) {
        return;
      }

      this.resetOptions();
      this.resetArrowCounter();
    },

    setArrowCounter(number) {
      this.arrowCounter = number;
    },

    fixScrolling() {
      this.$refs[`option_${this.arrowCounter}`][0].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    },

    resetArrowCounter() {
      this.arrowCounter = 0;
    },

    onSelect() {
      const selected = this.mutableOptions[this.arrowCounter];
      const selectedOption = this.items.find(
        (o) => o[this.valueKey] === selected[this.valueKey]
      );

      // if (selectedOption) {
      //     this.$emit('select', selectedOption);
      //     this.search = selectedOption[this.labelKey];
      //     this.emitInput();
      //     this.resetOptions();
      //     this.resetArrowCounter();
      // }
    },

    findProducts(name) {
      this.$emit("itemSearchProducts", name);
    },

    actualllySelected(value, flag) {
      this.optionSelected = value;
      this.$emit("optionSelecteActually", value);
       if (flag== 1) {
            this.Imoveis = 1
        }else if(flag == 2){
            this.Imoveis = 2

          window.location.href = '/findProductsByDistrict/filters/filters';
        }else{
            this.Imoveis = 0
        }
    },

    actuallyRoute(value) {
      this.$emit("routeActuallySelected", value);
    },

    updateLocalData() {
      locale.value = langStore.state.auth.lang; // Altera o idioma
    },

    changeLanguage(localLang) {
      langStore.dispatch("auth/localLang", localLang);
    },
  },

  watch: {
    value(value) {
      this.search = value;
    },

    items() {
      this.cloneOptions();
    },
  },

  created() {
    this.search = this.value;

    console.log("s", this.items[this.valueKey]);

    if (this.items.length) {
      this.cloneOptions();
    }
  },
};
</script>

<style scoped>
.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 120px;
  min-height: 1em;
  max-height: 6em;
  overflow: auto;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result .map {
  margin-left: 5px;
  margin-right: 4px;
}

.autocomplete-result:hover {
  background-color: #4aae9b;
  color: white;
}
</style>
