<template>
     <!-- Topbar Start -->
    <div class="container" >
      
        <div class="row align-items-center bg-light py-3 d-none d-lg-flex">
            <!-- <div class="col-lg-4">
                <a href="/" class="text-decoration-none"> -->
                    <!-- <span class="h1 text-uppercase text-primary px-2">Anucios</span> -->
                    <!-- <span class="h1 text-uppercase text-dark bg-primary ml-n1">Shop</span> -->
                <!-- </a>
            </div> -->
            <!-- <div class="col-lg-4 col-6 text-left">
                     <div class="input-group">
                        <input type="text"
                        :value="search"
                        ref="input"
                        @input="onInput($event.target.value)"
                         class="form-control" placeholder="Pesquise o seu produto" style="border-right:none">
                        <div class="input-group-append" @click="resetOptions" >
                            <span class="input-group-text bg-transparent text-primary">
                                <i class="fa fa-times text-white"></i>
                            </span>
                        </div>
                    </div>
                      <ul v-show="mutableOptions.length" class="autocomplete-results"> 
                            <li   class="autocomplete-result" @click="setResult(opt.title)" v-for="(opt, index) in mutableOptions" 
                            :key="opt[valueKey]"
                            :ref="`option_${index}`"
                            tabindex="0"
                            >{{ opt.title }}</li>
                        </ul>
             </div> -->
            <!-- <div class="col-lg-4 col-6 text-right">
                <p class="m-0">Atendimento ao Cliente</p>
                <h5 class="m-0">+25884000000</h5>
            </div> -->
        </div>
    </div>
    <!-- Topbar End -->
</template>

<script>
export default {
     props:{
            products:{
                type:Array,
                required:false,
                default:()=>[]


            },
             isAsync: {
                type: Boolean,
                required: false,
                default: false,
            },

            value: {
                type: String,
                default: '',
            },

            labelKey: {
                type: String,
                default: 'label',
            },

            valueKey: {
                type: String,
                default: 'id',
            },

             searchMinLength: {
                type: Number,
                default: 3,
            },

        },
        data(){

            return{
                 isLoading: false,
                 isOpen:false,
                 search: '',
                results: [],
                originalOptions:[],
                mutableOptions:[],
                arrowCounter:0

            }
        },

        mounted(){
            document.addEventListener('click', this.handleClickOutside);
        },
        destroyed(){
            document.removeEventListener('click', this.handleClickOutside);
        },

       

        methods:{  
            onInput(evt){
                this.search = evt;
                this.emitInput();



                if (evt.length >= this.searchMinLength) {
                    if (!this.originalOptions.length) {
                        this.$emit('shouldSearch', evt);
                     } else {
                        // this.searchInternally();
                    }
                } else {
                     this.resetOptions();
                }
                // if (this.isAsync) {
                //     this.isLoading = true;

                // }else{
                //     this.filterResults();
                //     this.isOpen = true;
                // }
                 

            },

            setResult(result) {
                this.search = result;
                this.isOpen = false;
            },


            filterResults() {
                this.results = this.products.filter(item => item.toLowerCase().indexOf(this.search.toLowerCase()) > -1);
            },

            handleClickOutside(event) {
                if (!this.$el.contains(event.target)) {
                    this.isOpen = false;
                }
            },

            cloneOptions(){
                this.originalOptions = this.products;
                this.mutableOptions = this.products;
                // this.searchInternally();
            },

            searchInternally() {
                const keyword = this.search;
                 
                this.mutableOptions = this.originalOptions.filter(o => o[this.labelKey].toLowerCase().search(keyword.toLowerCase()) >= 0);
                this.highlightOptions();
            },

            highlightOptions() {
                const keyword = this.search;
                const query = new RegExp(keyword, 'i');

                this.mutableOptions.forEach((o) => {
                    this.$set(o, `${this.labelKey}_highlighted`, o[this.labelKey].replace(query, '<span class="font-bold">$&</span>'));
                });
            },


            
            resetOptions() {
                this.originalOptions = [];
                this.mutableOptions = [];
            },

            emitInput() {
                this.$emit('input', this.keyword);
            },

            resetKeyword() {
                this.keyword = '';
                this.emitInput();
            },

            onSelect() {
                const selected = this.mutableOptions[this.arrowCounter];
                const selectedOption = this.products.find(o => o[this.valueKey] === selected[this.valueKey]);

                if (selectedOption) {
                    this.$emit('select', selectedOption);
                    this.search = selectedOption[this.labelKey];
                    this.emitInput();
                    this.resetOptions();
                    this.resetArrowCounter();
                }
            },

 

        },

        watch: {
            value(value) {
                this.search = value;
            },

            products() {
                this.cloneOptions();
            },
        },

        created(){
             this.search = this.value;

            if (this.products.length) {
                this.cloneOptions();
            }
        }

    }

 
</script>

<style scoped>

 .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    min-height: 1em;
    max-height: 6em;    
    overflow: auto;
    z-index: 1000;
    position: absolute;
    min-width: 22rem;
    background-color: #fff;
    background-clip: padding-box;

  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result .map{
    margin-left: 5px;
    margin-right: 4px;
  }

   .autocomplete-result:hover {
    background-color: #4AAE9B;
    color: white;
  }
 
</style>