<template>
  <!-- Topbar Start -->

  <BarMenu :fullName="fullName" :lang="lang" />

  <!-- <div class="demo-register">
    <div class="text-center mb-5 mt-4">
      <img
        src="https://terrenos.co.mz/TerrenosLogo.png"
        class="img-fluid"
        style="width: 100px; height: 100px"
        alt="logo"
      />
    </div>
    <Login ref="form" @on-submit="handleSubmit">
      <h4 class="text-center mb-4">{{ $t("FormLogin.AccessAccount") }}</h4>

      <Email name="email" placeholder="Email"  @input="validateEmail" />
      <p v-if="emailError" style="color: red">{{ emailError }}</p>
      <Password
        name="password"
        :rules="passwordRule"
        :placeholder="$t('FormLogin.Password')"

      />
      <p v-if="emailError" style="color: red">{{ emailError }}</p>
      <Poptip trigger="focus" placement="right" width="300">
     
      </Poptip>
      <Submit
        style="
          background-color: #6f42d8;
          color: #fff;
          border: none;
          border-radius: 6px;
          padding: 3px;
        "
      >
        {{
          loading == false ? $t("FormLogin.Access") : $t("FormLogin.Accessing")
        }}</Submit
      >
      <div class="form-row d-flex justify-content-between mt-4 mb-2 col-lg-12">
        <div class="form-group">
          <div class="custom-control custom-checkbox ml-1">
            <input
              type="checkbox"
              class="custom-control-input"
              id="basic_checkbox_1 "
            />
            <label class="custom-control-label" for="basic_checkbox_1 ">{{
              $t("FormLogin.RemberMe")
            }}</label>
          </div>
        </div>
        <div class="form-group">
          <router-link to="/forgot-password" class="text-primary"
            >{{ $t("FormLogin.ForgotPassword") }}?
          </router-link>
        </div>
      </div>
    </Login>
    <div class="new-account mt-3">
      <p>
        {{ $t("FormLogin.SignUp") }}?
        <router-link to="/inscription" class="text-primary">{{
          $t("FormLogin.clickTitle")
        }}</router-link>
      </p>
    </div>
  </div> -->

  <div class="authincation h-100">
    <div class="container h-100">
      <div class="car mx-auto m-2" style="width: 400px; margin-top: 16em">
        <div class="row h-100 p-5">
          <div class="car col-md-12">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="text-center mb-5 mt-4">
                    <img
                      src="/TerrenosLogo.png"
                      class="img-fluid"
                      style="width: 100px; height: 100px"
                      alt="logo"
                    />
                  </div>
                  <div class="auth-form">
                    <h4 class="text-center mb-4">
                      {{ $t("FormLogin.AccessAccount") }}
                    </h4>
                    <form @submit.prevent="handleSubmit">
                      <div class="form-group">
                        <div class="input-group-append">
                          <input
                            type="text"
                            class="form-control col-lg-10"
                            placeholder="Email"
                            v-model="data.email"
                            @input="validateEmail"
                          />
                          <div class="input-group-text">
                            <span class="fa fa-envelope"></span>
                          </div>
                        </div>
                        <p v-if="emailError" style="color: red">
                          {{ emailError }}
                        </p>
                      </div>

                      <div class="form-group">
                        <div class="input-group-append">
                             <input
                              type="password"
                              class="form-control col-lg-10"
                              :placeholder="$t('FormLogin.Password')"
                              v-model="data.password"
                            />
                             
                           <div class="input-group-text">
                            <span class="fa fa-key"></span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="form-row d-flex justify-content-between mt-4 mb-2 col-lg-12"
                      >
                        <div class="form-group">
                          <div class="custom-control custom-checkbox ml-1">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="basic_checkbox_1 "
                            />

                            <label
                              class="custom-control-label"
                              for="basic_checkbox_1 "
                              >{{ $t("FormLogin.RemberMe") }}</label
                            >
                          </div>
                        </div>
                        <div class="form-group">
                          <router-link
                            to="/forgot-password"
                            class="text-primary"
                            >{{ $t("FormLogin.ForgotPassword") }}?
                          </router-link>
                        </div>
                      </div>
                      <div class="text-center mb-2">
                        <button
                          type="submit"
                          class="btn-block"
                          style="
                            background-color: #6f42d8;
                            color: #fff;
                            border: none;
                            border-radius: 6px;
                            padding: 8px;
                          "
                          :loading="loading"
                        >
                          {{
                            loading == false
                              ? $t("FormLogin.Access")
                              : $t("FormLogin.Accessing")
                          }}
                        </button>
                      </div>
                    </form>
                    <div class="new-account mt-3">
                      <p>
                        {{ $t("FormLogin.SignUp") }}?
                        <router-link to="/inscription" class="text-primary">{{
                          $t("FormLogin.clickTitle")
                        }}</router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, computed } from "vuex";
import toastr from "toastr";
import axios from "axios";
import Header from "../../components/Header.vue";
import BarMenu from "@/components/BarMenu.vue";
import store from "@/store";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  components: {
    Header,
    BarMenu,
  },
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value !== this.$refs.form.formValidate.password) {
        callback(
          new Error("A palavra-passe introduzida duas vezes não corresponde!")
        );
      } else {
        callback();
      }
    };
    return {
      data: {
        email: "admin@terrenos.co.mz",
        // email: 'info@administrador.co.mz',
        password: "Nova1234",
        // password: 'uZU65e1O'
      },
      emailError: "",
      loading: false,
      fullName: null,
      route: "",
      path: "",
      lang: store.state.auth.lang,
      showPassword: false,
      URL_API:process.env.VUE_APP_URL_API,
       URL:process.env.VUE_APP_URL
    };
  },

  computed: {
    validateEmail() {
      // Expressão regular para validar o formato de um e-mail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Verifica se o e-mail atende ao formato esperado
      if (!emailRegex.test(this.data.email)) {
        this.emailError = "Por favor, insira um endereço de e-mail válido.";
      } else {
        this.emailError = null;
      }
    },
    passwordTip() {
      let strong = "forte";
      let className = "strong";
      let percent = this.passwordLen > 10 ? 10 : this.passwordLen;
      let color = "#19be6b";

      if (this.passwordLen < 6) {
        strong = "Muito curto";
        className = "low";
        color = "#ed4014";
      } else if (this.passwordLen < 10) {
        strong = "médio";
        className = "medium";
        color = "#ff9900";
      }

      return {
        strong,
        class:
          "demo-register-tip-" + this.passwordLen < 6
            ? "low"
            : this.passwordLen < 10
            ? "medium"
            : "strong",
        percent: percent * 10,
        color,
      };
    },
  },

  mounted() {
    this.route = useRoute();
    this.path = this.route.fullPath;
    console.log(store.state.auth.lang);
    if (store.state.auth.authenticated == true) {
      this.fullName = store.state.auth.user.fullName;
    }
    this.lang = store.state.auth.lang;
  },

  methods: {
    showPasswordClick() {
      this.showPassword = !this.showPassword;
    },
    ...mapActions({
      signIn: "auth/login",
    }),
    handleChangePassword(val) {
      this.passwordLen = val.length;
    },

    async handleSubmit() {
      this.loading = true;

      if (this.data.email == "" || this.data.password == "") {
        this.loading = false;
        return;
      }

      await axios
        // .get("http://127.0.0.1:8000/sanctum/csrf-cookie")
        .get(this.URL+"sanctum/csrf-cookie")
        .then((response) => {
          axios
            // .post("/api/login", this.data)
            .post(this.URL_API+"login", this.data)
            .then(({ data }) => {
              if (data.status === false) {
                toastr.error(
                  "Desculpe, parece que houve um erro. O nome de usuário ou senha inseridos estão incorretos. Por favor, verifique suas informações e tente novamente"
                );
                return;
              }

              localStorage.setItem("user", JSON.stringify(data));
              toastr.success("Bem vindo!! acedeu com sucesso");
              this.signIn(data);
            })
            .catch((response) => {
              toastr.error(
                "Erro de autenticacao verifique suas credenciais ou contacte o administrador" +
                  response.data.message
              );
            })
            .finally(() => {
              this.loading = false;
            });
        });
    },

    //   async handleSubmitButton() {
    //     this.loading = true;
    //     const apiUrl = process.env.VUE_APP_API_URL;

    //     axios
    //       .post(`https://anucios.jdesign.co.mz/Api/api/login`, this.data)
    //       .then(({ data }) => {
    //         if (data.login == false) {
    //                     Swal.fire({
    //                   position:'top-end',
    //                   icon:'success',
    //                   title:'Usuário ou senha inválida',
    //                   showConfirmButton:false,
    //                   timer:1500
    //                 })
    //         } else {
    //           localStorage.setItem("user", JSON.stringify(data));
    //                  Swal.fire({
    //                   position:'top-end',
    //                   icon:'success',
    //                   title:'Bem vindo!! acedeu com sucesso',
    //                   showConfirmButton:false,
    //                   timer:1500
    //                 })

    //           this.signIn(data);
    //           // this.loggedYear(this.data.yearLog);
    //         }
    //       })
    //       .catch((response) => {

    //              Swal.fire({
    //                   position:'top-end',
    //                   icon:'error',
    //                   title:'Erro de autenticacao verifique suas credenciais ou contacte o administrador',
    //                   showConfirmButton:false,
    //                   timer:1500
    //                 })

    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    //   },
  },
};
</script>
<style scoped>
.demo-register {
  width: 400px;
  margin: 0 auto !important;
}
.demo-register .ivu-poptip,
.demo-register .ivu-poptip-rel {
  display: block;
}
.demo-register-tip {
  text-align: left;
}
.demo-register-tip-low {
  color: #ed4014;
}
.demo-register-tip-medium {
  color: #ff9900;
}
.demo-register-tip-strong {
  color: #19be6b;
}
.demo-register-tip-title {
  font-size: 14px;
}
.demo-register-tip-desc {
  white-space: initial;
  font-size: 14px;
  margin-top: 6px;
}
</style>
