import CryptoJS from 'crypto-js';

export default{

     generateHash(id){
        // ID que você deseja criptografar
        const idToEncrypt = id; // Substitua pelo seu ID real
    
        // Chave de criptografia (mantenha isso seguro)
        const encryptionKey = 'base64:5svOUiejBaS+sPVpmgp28enbJLhu+Prgzg1lvc23bUU=';
    
        // Criptografe o ID
        const encryptedId = CryptoJS.AES.encrypt(idToEncrypt.toString(), encryptionKey).toString();

        return encryptedId;
    },

     decryptId(id) {
        // ID que você deseja criptografar
     

        // Chave de criptografia (mantenha isso seguro)
        const encryptionKey = 'base64:5svOUiejBaS+sPVpmgp28enbJLhu+Prgzg1lvc23bUU=';

        // Criptografe o ID
         const encryptedId = CryptoJS.AES.decrypt(id, encryptionKey)
         const i = encryptedId.toString(CryptoJS.enc.Utf8);

        return i;
    }
}