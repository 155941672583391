<template>
  <!-- Footer Start -->
  <div
    class="container-fluid text-secondary  pt-5" 
    style="background-color: #081828;  margin-top:-4em"
  >
    <div class="row px-xl-5 pt-5 p-4">
      <div class="col-lg-4 col-md-12 mb-5 pr-3 pr-xl-5">
        <!-- <h5 class="text-secondary text-uppercase mb-4">Terrenos Trespasse</h5> -->
        <div class="col-lg-2 d-lg-block d-none" style="margin-top:-8px; margin-left:-14px">
          <a
            href="/"
            class="h1 text-uppercase text-primary px-2 btn d-flex align-items-center w-100"
            style="height: 65px; padding: 0 10px"
          >
            <img
               src="/TerrenosLogo.png"
              class="img-fluid"
              style="width: 35px; height: 35px"
              alt="logo"
            />
            <!-- <img src="http://localhost:8080/assets/img/logo/Terrenos - Trespasse de Espaço (Blue).png" class="img-fluid" style="width:50px; height:50px" alt=""> -->
          </a>
        </div>
        <p class="mb-4">
        {{ $t("Footer.aboutSite") }}
        </p>
        <p class="mb-2">
          <i class="fa fa-map-marker text-primary mr-3"></i>Av. Mohamed Siad
          Barre № 582,R/C Maputo
        </p>
        <p class="mb-2">
          <i class="fa fa-envelope text-primary mr-3"></i
          >terrenostrespasse@mail.mz
        </p>
        <p class="mb-0">
          <i class="fa fa-phone text-primary mr-3"></i>+25884000000
        </p>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="row">
          <div class="col-md-4 mb-5">
            <h5 class="text-secondary text-uppercase mb-4">Links</h5>
            <div class="d-flex flex-column justify-content-start">
              <a class="text-secondary mb-2" href="#"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.Links.Home") }}</a
              >
              <a class="text-secondary mb-2" href="#"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.Links.ProductDateils") }}</a
              >
              <a class="text-secondary mb-2" href="#"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.Links.Agents") }}</a
              >
              <a class="text-secondary" href="#"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.Links.ContactUs") }}</a
              >
              <a
                class="text-secondary"
                href="javascript:void(0)"
                @click="handeleButton"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.Links.HelpUs") }}</a
              >
            </div>
          </div>
          <div class="col-md-4 mb-5">
            <h5 class="text-secondary text-uppercase mb-4">
              {{ $t("Footer.MyAccount.MyAccount") }}
            </h5>
            <div class="d-flex flex-column justify-content-start">
              <a class="text-secondary mb-2" href="#"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.MyAccount.WhyTransapss") }}</a
              >
              <a class="text-secondary mb-2" href="#"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.MyAccount.Contacts") }}</a
              >
              <a class="text-secondary mb-2" href="#"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.MyAccount.PolicePrivicy") }}</a
              >
              <a class="text-secondary mb-2" href="#"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.MyAccount.CondOfUse") }}</a
              >
              <a class="text-secondary" href="#"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.MyAccount.AcessYouData") }}
              </a>
              <a class="text-secondary" href="#"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.MyAccount.PrivacyState") }}
              </a>
              <a class="text-secondary" href="#"
                ><i class="fa fa-angle-right mr-2"></i
                >{{ $t("Footer.MyAccount.SafetyTips") }}
              </a>
            </div>
          </div>
          <div class="col-md-4 mb-5">
            <h5 class="text-secondary text-uppercase mb-4">
              {{ $t("Footer.MyAccount.Newsletter") }}
            </h5>
            <p>{{ $t("Footer.MyAccount.KeepInformed") }}</p>
            <form action="">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  :placeholder="$t('Footer.MyAccount.YourEmail')"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary">
                    {{ $t("Footer.MyAccount.SignUp") }}
                  </button>
                </div>
              </div>
            </form>
            <h6 class="text-secondary text-uppercase mt-4 mb-3">
              {{ $t("Footer.MyAccount.FallowUs") }}
            </h6>
            <div class="d-flex">
              <a class="btn btn-primary btn-square mr-2" href="#"
                ><i class="fa fa-twitter"></i
              ></a>
              <a class="btn btn-primary btn-square mr-2" href="#"
                ><i class="fa fa-facebook"></i
              ></a>
              <a class="btn btn-primary btn-square mr-2" href="#"
                ><i class="fa fa-linkedin"></i
              ></a>
              <a class="btn btn-primary btn-square" href="#"
                ><i class="fa fa-instagram"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row border-top mx-xl-5 py-4"
      style="border-color: rgba(256, 256, 256, 0.1) !important"
    >
      <div class="col-md-6 px-xl-0">
        <p class="mb-md-0 text-center text-md-left text-secondary">
          &copy; All Rights Reserved. Designed by
          <a class="text-primary" href="https://htmlcodex.com">Prosofty</a>
        </p>
      </div>
      <!-- <div class="col-md-6 px-xl-0 text-center text-md-right">
                    <img class="img-fluid" src="img/payments.png" alt="">
                </div> -->
    </div>
  </div>
  <!-- Footer End -->
</template>

<script setup>
import { ref, defineEmits } from "vue";
// const {props} =  defineProps(["shoModal"])
const emits = defineEmits(["modalShow"]);

function handeleButton() {
  emits("modalShow", 1);
}
</script>



 