<template>
<div class="container">
   <div class="row bg-secondary py-1 d-block d-lg-none">
            <!-- <div class="col-lg-6 d-none d-lg-block">
                <div class="d-inline-flex align-items-center h-100">
                    <a class="text-body mr-3" href="">About</a>
                    <a class="text-body mr-3" href="">Contact</a>
                    <a class="text-body mr-3" href="">Help</a>
                    <a class="text-body mr-3" href="">FAQs</a>
                </div>
            </div> -->
            <div class="col-lg-6 text-center text-lg-right ">
                <div class="d-inline-flex align-items-center">
                    <div class="btn-group">
                        <button type="button" class="btn btn-sm btn-light dropdown-toggle" data-toggle="dropdown">Minha Conta</button>
                        <div class="dropdown-menu dropdown-menu-right">
                        <i class="fa-solid fa-right-from-bracket"></i>
                            <a v-if="userLogged == true && role == 'Agent'" class="btn d-flex align-items-center justify-content-between bg-white w-100" style="height: 45px;">
                                <h6 class="text-dark m-0"><i class="fa fa-user mr-2 text-primary"></i> <router-link class="text-primary" to="/profile" style="font-size:12px; text-decoration:none;"> Perfil</router-link></h6>
                                <i class="fa fa-logout text-dark"></i>
                            </a>

                            <a v-if="userLogged == true" class="btn d-flex align-items-center justify-content-between bg-white w-100" style="height: 45px;">
                                <h6 class="text-dark m-0"><i class="fa fa-user mr-2 text-primary"></i> <router-link class="text-primary" to="/myData" style="font-size:12px; text-decoration:none;"> Meus Dados</router-link></h6>
                                <i class="fa fa-logout text-dark"></i>
                            </a>
                              <a v-if="userLogged == true" class="btn d-flex align-items-center justify-content-between bg-white w-100" style="height: 45px;">
                                <h6 class="text-dark m-0"><i class="fa fa-lock mr-2 text-primary"></i> <router-link class="text-primary" to="/update_password" style="font-size:12px; text-decoration:none;"> Credencias</router-link></h6>
                                <i class="fa fa-logout text-dark"></i>
                            </a>
                            <router-link class="dropdown-item" v-if="!userLogged" to="/login" type="button">Entrar</router-link>
                            <!-- <a v-else href="" class="btn d-flex"> -->
<!--                             
                              <button @click="logout"  class="dropdown-item" type="button"><box-icon name='log-in-circle'></box-icon> </button>
                              <span>Log out</span> -->

                             <a  v-if="userLogged == true"  @click="logout" class="btn d-flex align-items-center justify-content-between bg-white w-100" style="height: 45px; padding: 0 10px;">
                                <h6 class="text-dark m-0"><i class="fa fa-sign-out mr-2 text-danger"></i> <span style="font-size:12px"> Terminar Sessão</span></h6>
                                <i class="fa fa-logout text-dark"></i>
                            </a>

                          
                        </div>
                    </div> 
                </div>
                <div class="d-inline-flex align-items-center d-block d-lg-none">
                    <a href="" class="btn px-0 ml-2">
                        <i class="fas fa-heart text-dark"></i>
                        <span class="badge text-dark border border-dark rounded-circle" style="padding-bottom: 2px;">0</span>
                    </a>
                    <a href="" class="btn px-0 ml-2">
                        <i class="fas fa-shopping-cart text-dark"></i>
                        <span class="badge text-dark border border-dark rounded-circle" style="padding-bottom: 2px;">0</span>
                    </a>
                </div>
            </div>
        </div>

</div>
</template>

<script>
import { mapActions } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import router from '@/router';

export default {
  props:["userLogged", "role"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      user:null,
      isPrescription: this.$store.state.auth.isPrescription,
      modules: [Autoplay, Pagination, Navigation],
    };
  },
  methods: {
    ...mapActions({
      signOut: "auth/logout",
    }),
    async logout() {
       
      this.signOut();
      localStorage.removeItem("user");
      // this.$router.push({ name: "Home" });
      window.location.href = "/"
    },

    signIn(){
      this.$router.push("/login");
    }
  },

  created(){
    this.user =  this.$store.state.auth.user;
  }
};
</script>
  