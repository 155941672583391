
<template>
  <Form :model="formData" @submit.prevent="handleSubmitButton">
  
     <Row :gutter="32">
      <Col span="12">
        <FormItem label="Contacto*" label-position="top">
          <Input
            v-model="formData.contact"
            placeholder="Informe seu Contacto"
          />
        </FormItem>
      </Col>
      <Col span="12">
        <FormItem label="Email*" label-position="top">
          <Input v-model="formData.email" placeholder="Informe seu Email" />
        </FormItem>
      </Col>
    </Row>
  
    <FormItem label="Descrição*" label-position="top">
      <Input
        type="textarea"
        v-model="formData.description"
        :rows="4"
        placeholder="Por Deixe seu feedback aqui..."
      />
    </FormItem>
 
    <Row :gutter="32" style="text-align: right">
      <div class="demo-drawer-footer">
     
        <Button
          type="primary" 
          @click="handleSubmitButton"
          >Enviar</Button
        >
      </div>
    </Row>
  </Form>
</template>
<script setup>
import { ref, defineEmits } from "vue"; 
const emits = defineEmits(["submitDataService"]);


const formData = ref({
              description: '',
              contact: '',
              email: '',
    });

function handleSubmitButton(){
      
    emits('submitDataService', formData.value)
}

</script>

 
<style scoped>
.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.vertical-center-modal .ivu-modal {
  top: 0;
}

.demo-drawer-footer{
        width: 100%;
        bottom: 0;
        left: 0;
        border-top: 1px solid #e8e8e8;
        padding: 10px 16px;
        text-align: right;
        background: #fff;
    }
</style>